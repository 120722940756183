.share-form .ant-input,
.share-form .ant-select-selector,.ant-select-dropdown {
    border-radius: 0 !important; 
}
.share-button{
    width: 70px;
    border-radius: 5px;
    margin-left: auto;
}
.select-input
{
    width: 172px;
}
.restricted-container{
    display: flex;
    flex-direction: column;
}
.doc-member-container{
    margin-top: 16px;
}

@media (max-width: 575px)
{
    .select-dropdwon,.ant-form-item-label,  .ant-form-item-control{
        flex: initial !important;
        width: auto;
    }
}
@media (max-width: 500px)
{
    .share-form{
        flex-wrap: wrap;
    }
    .share-button{
        width: 200px;
        right: 30%;
        margin-top: -8px;
    }
    .share-input{
            margin-top: 3px;
    }
    .select-input .ant-form-item-control
    {
        width: 110px !important;
        margin-right: -5px !important;
    }

}