.sharebtn img{
    height:'30px'

}
.share-doc-modal .ant-modal-content {
    min-width: 500px;
    width: max-content !important;
}
@media screen and (max-width:768) {
    .sharebtn{
        height:'120px'

    }    
}
