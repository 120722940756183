p,h4{
    margin-bottom: 0;
}
.ownerInfo{
    margin: 10px 0px ;
    padding: 5px;
    display: flex;
    align-items: center;
}

.info{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;
}

.avatar{
    margin:0px 10px;
    margin-top: -22px;
}
.badge{
    margin-right: 10px;
}
.publicShareBtnsContainer{
    display: flex;
    gap: 16px;
    float: right;
    margin-top: 16px;
    margin-bottom: -16px;
    margin-right: -20px;
    width: fit-content;
}
.shareBtn{
    background-color: rgb(82, 107, 177) !important;
    color: rgb(255, 255, 255) !important;
    border-radius: 8px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.shareBtn:hover {
    background-color: rgb(72, 97, 167);
    color: white !important;
    border: none;
}

.shareBtn:disabled, 
.shareBtn[disabled] {
    background-color: rgb(200, 200, 200) !important;
    color: rgb(150, 150, 150) !important;
    cursor: not-allowed;
}
.removeAccessBtn{
    background-color: rgb(219, 82, 82) !important;
    color: white !important;
    border-radius: 8px !important;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.removeAccessBtn:hover{
    background-color: rgb(219, 82, 82) !important;
    border: none !important;
}

@media screen and (max-width: 480px) {
    p{
        font-size: 11px;
    }
}

